import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import BlockSharpIcon from "@mui/icons-material/BlockSharp";
import axios from "axios";
import { useSelector } from "react-redux";
import Deletepopup from "./Deletepopup";
import Addpopup from "./Addpopup";
import BlockUser from "./BlockUser";
import { notify } from "../../utils/notify";
import ShowFavouriteBeaches from "../modals/showFavBeaches";
import Addblockagain from "./Addblockagain";
import CircularProgress from "@mui/material/CircularProgress";
import Loadering from "../../../assets/images/loading.gif";
import UserUpdate from "./UserUpdate";
import Avatar from "../../../assets/images/avatar.png";
import AvatarPlaceholder from "../../../assets/images/ProfilePlaceholder.svg";
import UserView from "./UserView";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Caution from "../../../assets/images/caution.jpg";
import { Button } from "@material-ui/core";
import AddUser from "../users/AddUser";
import XLSX from "xlsx";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortIcon from "@mui/icons-material/Sort";
import moment from "moment";
import Switch from "@mui/material/Switch";
import InfinitScroll from "react-infinite-scroll-component";
import LinearProgress from "@mui/material/LinearProgress";
import FormDialog from "../dialog";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import {
  UserAPIwithFilter,
  UserapiData,
  favouriteBeachesData,
} from "../../services/UserApi";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import ProgressiveImage from "react-progressive-graceful-image";
import { Pagination } from "@mui/material";

export default function BasicTable(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(1);
  const [dataUser, setdataUser] = useState([]);
  const [open, setOpen] = useState(props.open);
  const [loader, setLoader] = useState(false);
  const [netcheckk, setNetcheckk] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader3, setLoader3] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [loader6, setLoader6] = useState(false);
  const [favBeaches, setFavBeaches] = useState([]);
  const [blockedyes, setBlockedyes] = useState("");
  const [change, setChange] = useState(false);
  const [pgNo, setpgNo] = useState(1);
  const [perPg, setperPg] = useState(10);
  const [searchMenu, setsearchMenu] = useState("");
  const [sortedField, setSortedField] = React.useState(null);
  const [fileterbool, setFilterBool] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fdate, setFdate] = useState("");
  const [lDate, setLdate] = useState("");
  const [delId, setdelId] = useState();
  const [blockhai, setblockhai] = useState();
  const [addId, setaddId] = useState();
  const [excelarray, setdownloadarray] = useState([]);
  const [openFav, setOpenFave] = React.useState(false);

  const handleClickOpenFav = () => {
    setOpenFave(true);
  };

  const handleCloseFav = () => {
    setOpenFave(false);
    setFavBeaches([]);
  };
  const [state, setState] = useStateWithCallbackLazy({
    pgNo: 1,
    perPg: 10,
    loader: true,
  });
  const closeModal = () => setFilterBool(false);
  let sortedProducts = [...dataUser];

  const downloadData = [];

  const UserViews = (resp) => {
    setLoader5(true);
    setdelId(resp);
  };
  const newAddim = (val) => {
    let changing = [...dataUser];
    changing.unshift(val);
    setdataUser(changing);
  };
  const [updated, setUpdated] = useState(false);
  const [Totalarray, settoTalarray] = useState("");
  const updatestate = (message) => {
    setUpdated((prev) => !prev);
    notify(`${message}`, "success");
  };
  const handleblocked = (id_block) => {
    let statusblock;
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id_block);
    if (changing[findelement].isBlock == true) {
      statusblock = false;
    } else {
      statusblock = true;
    }
    changing[findelement].isBlock = statusblock;
    setdataUser(changing);
    setBlockedyes("");
  };
  const justblocked = (id_block) => {
    let statusblock;
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id_block);
    changing[findelement].isBlock = statusblock;
    setdataUser(changing);
    setBlockedyes("");
  };
  const handledelete = (id_delete) => {
    let statusblock;
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id_delete);
    if (changing[findelement].isDelete == true) {
      statusblock = false;
    } else {
      statusblock = true;
    }

    changing[findelement].isDelete = statusblock;
    setdataUser(changing);
    setBlockedyes("");
  };
  const handleupdate = (id, obj, pic) => {
    let temp = [...dataUser];
    let index = dataUser.findIndex((i) => i._id == id);

    let clone = { ...temp[index], ...obj };

    temp[index] = clone;
    setdataUser(temp);

    // let changing = [...dataUser];
    // let findelement = changing.findIndex((ii) => ii._id == id);
    // changing[findelement].firstName = obj.firstName;
    // changing[findelement].lastName = obj.lastName;
    // changing[findelement].phoneNumber = obj.phoneNumber;
    // changing[findelement].profilePic = pic;
    // setdataUser(changing);
    setChange(false);
    setUpdated(false);
  };
  const Userupdated = (resp) => {
    setLoader4(true);
    setaddId(resp);
  };
  const dialog = (id, blockhai) => {
    setLoader(true);
    setdelId(id);
    setblockhai(blockhai);
  };
  const addagain = (id) => {
    setLoader1(true);
    setaddId(id);
  };
  const blockUser = (id) => {
    setLoader2(true);
    setdelId(id);
  };
  const addagainblock = (id) => {
    setLoader3(true);
    setaddId(id);
  };
  const adduser = () => {
    setLoader6(true);
  };
  const netcheck = () => {
    if (!window.navigator.onLine) {
      notify("Network not found");
      setOpen(false);
      setNetcheckk(true);
    }
  };
  const emptyforce = () => {
    setdataUser([]);
    setState({
      ...state,
      pgNo: 1,
    });
    // setpgNo(1)
    setUpdated((prev) => !prev);
  };
  const handleAge = (age) => {
    if (age && age != "") {
      const myArray = age.split("/");
      var newAge = myArray[1] + "/" + myArray[0] + "/" + myArray[2];
      var nDate = new Date(newAge);
      var today = new Date();
      var distance = today.getTime() - nDate.getTime();
      var daysOld = Math.floor(distance / (1000 * 60 * 60 * 24));
      var yearsOld = Number((daysOld / 365).toFixed(0));
      return yearsOld;
    }
    return "";
  };

  const {
    user: { user },
  } = useSelector((state) => state);

  useEffect(() => {
    netcheck();
    // api.orphanboards.com
    let query = `?pageNo=${currentPage}&perPage=${10}${
      searchMenu ? `&search=${searchMenu}` : ""
    }${fdate ? `&startDate=${fdate}` : ""}${lDate ? `&endDate=${lDate}` : ""} `;

    favouriteBeachesData(query)
      // UserapiData()
      .then(({ data }) => {
        setCount(data?.data?.count);
        // console.log("data", data?.data?.users)
        setState({
          ...state,
          loader: false,
        });
        if (data) {
          setOpen(false);
        }
        if (data && data.data && data.data?.users?.length) {
          let newww = [...dataUser];

          data.data.users.forEach((element) => {
            let findAnyelement = newww.findIndex((ii) => ii._id == element._id);
            if (findAnyelement < 0) {
              newww = [...newww, element];
            }
          });
          // setdataUser(newww);
          settoTalarray(data.data.count);
        }
        setdataUser(data?.data?.users);
        setdownloadarray(data?.data?.users);
      })
      .catch((err) => {
        setState({
          ...state,
          loader: false,
        });
      });
  }, [updated, change, open, currentPage, fdate, lDate]);

  const downloadapi = () => {
    // UserapiData()
    //   .then(({ data: { data } }) => {
    // setdownloadarray(data.users);

    downloadExcel(dataUser);
    // setOpen(false);
    // })
    // .catch((err) => { });
  };
  const filterfun = () => {
    setSortedField("");
  };
  const getApiCall = () => {
    // setpgNo(pgNo + 1);
    setState({
      ...state,
      pgNo: state.pgNo + 1,
      loader: true,
    });
    setChange((prev) => !prev);
  };

  const downloadExcel = (resp) => {
    console.log("resp", resp);
    downloadData.length = 0;
    resp.map((items, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Created_Date: moment(items.createdAt).format("MMM Do YY"),
        First_Name: items.firstName,
        Last_Name: items.lastName,
        Email: items.email,
        Gender: items.gender,
        Age: items.age,
        PostalCode: items.zipCode,
        Active_Status: items.isDelete ? "Delete" : "Active",
        Live_Status: items.isBlock ? "Blocked" : "Live",
        ReportBy: items?.email,
      };
      downloadData.push(tempObj);
    });

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Products");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Users Data.xlsx");
  };

  if (sortedField !== null) {
    sortedProducts.sort((a, b) => {
      if (a[sortedField] < b[sortedField]) {
        return -1;
      }
      if (a[sortedField] > b[sortedField]) {
        return 1;
      }

      return 0;
    });
  }
  const FilterDate = (firstdte, lstdte) => {
    netcheck();
    setState(
      {
        ...state,
        pgNo: 1,
      },
      (va) => {
        emptyforce();
        setFdate(firstdte);
        setLdate(lstdte);
        closeModal();
        setOpen(true);
      }
    );
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    // You can fetch new data based on the newPage or update the data to display.
  };

  const handleFilter = (start, end) => {
    setOpen(true);
    setCurrentPage(1);
    setFdate(start);
    setLdate(end);
    // tableWithFilter();
    // UserAPIwithFilter(currentPage, 10, start, end)
    //   .then(({ data }) => {

    //   })
    //   .catch((err) => {

    //   })
  };

  const handleOpenFavBeaches = (item) => {
    handleClickOpenFav();
    setFavBeaches(item?.favoritesBeaches);
    // console.log("item", );
  };

  return (
    <div className="users_table">
      <div className="titlee">
        <h2>Favourite Beaches</h2>
      </div>
      <div className="search_view">
        <input
          type="search"
          placeholder="Search..."
          autoComplete="off"
          value={searchMenu}
          onChange={(e) => {
            setsearchMenu(e.target.value);
            emptyforce();
          }}
        ></input>
      </div>
      {open ? (
        <div className="loaadds">
          <img src={Loadering} className="loaderr" />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            {/* <InfinitScroll
            dataLength={dataUser.length}
            next={getApiCall}
            hasMore={true}
            loader={state.loader ? <LinearProgress /> : ""}
          > */}
            <div className="excle_btn">
              {/* <Button
                className="dowloadButton"
                onClick={() => {
                  downloadapi();
                  // setOpen(true);
                }}
              >
                Download Users Data <FileDownloadIcon />
              </Button> */}
            </div>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="tableRowData">
                  {/* <TableCell className="tableHeadings" align="center">SR</TableCell> */}

                  <TableCell className="tableHeadings" align="center">
                    Profile
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    First Name
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("firstName")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Last Name
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("lastName")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Email
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("email")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Total favourite beaches
                  </TableCell>

                  <TableCell className="tableHeadings" align="center">
                    Favourite Beaches
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts.map((dats, i) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell align="center">{i + 1} </TableCell> */}

                        <TableCell align="center">
                          <ProgressiveImage
                            src={
                              dats?.profilePic
                                ? dats?.profilePic
                                : AvatarPlaceholder
                            }
                            placeholder={Loaadings}
                          >
                            {(src, loading) => (
                              <img
                                className={`profille image${
                                  loading ? " loading" : " loaded"
                                }`}
                                src={src}
                                alt={Avatar}
                              />
                            )}
                          </ProgressiveImage>
                        </TableCell>
                        <TableCell align="center">{dats?.firstName} </TableCell>
                        <TableCell align="center">{dats?.lastName} </TableCell>
                        <TableCell align="center">{dats?.email} </TableCell>
                        <TableCell align="center">
                          {dats?.favoritesBeaches?.length}
                        </TableCell>
                        <TableCell align="center">
                          <button
                            className="bttn btn-success"
                            type="button"
                            onClick={() => handleOpenFavBeaches(dats)}
                          >
                            <VisibilityIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>

            {loader ? (
              <Deletepopup
                id={delId}
                block={blockhai}
                updstate={updatestate}
                delete={handledelete}
                close={setLoader}
                blocked={handleblocked}
              />
            ) : (
              ""
            )}
            {loader1 ? (
              <Addpopup
                id={addId}
                updstate={updatestate}
                adddelete={handledelete}
                close={setLoader1}
              />
            ) : (
              ""
            )}
            {loader2 ? (
              <BlockUser
                id={delId}
                updstate={updatestate}
                blocked={handleblocked}
                close={setLoader2}
              />
            ) : (
              ""
            )}
            {loader3 ? (
              <Addblockagain
                id={addId}
                updstate={updatestate}
                unblocked={handleblocked}
                close={setLoader3}
              />
            ) : (
              ""
            )}
            {loader4 ? (
              <UserUpdate
                id={addId}
                updstate={updatestate}
                update={handleupdate}
                close={setLoader4}
                title="User"
                setOpen={setOpen}
              />
            ) : (
              ""
            )}
            {loader5 ? <UserView resp={delId} close={setLoader5} /> : ""}
            {loader6 ? (
              <AddUser
                updstate={updatestate}
                close={setLoader6}
                newAddAdmin={newAddim}
                title="User"
              />
            ) : (
              ""
            )}
            {/* </InfinitScroll> */}
          </TableContainer>
          {!open && (
            <div className="paginationContainer">
              <Pagination
                count={Math.ceil(count / 10)}
                page={currentPage}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
                sx={{
                  "& .MuiPaginationItem-root": {
                    height: "50px",
                    width: "50px",
                    fontSize: "1rem",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#488fbf !important",
                    color: "white",
                    border: "none",
                  },
                }}
              />
            </div>
          )}
        </>
      )}
      {netcheckk ? (
        <div className="loaderr">
          <img src={Caution}></img>
          <h3>Your connection is not available</h3>
        </div>
      ) : (
        ""
      )}
      <FormDialog
        open={fileterbool}
        handleClose={closeModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        callback={FilterDate}
        handleFilter={handleFilter}
      />
      <ShowFavouriteBeaches
        open={openFav}
        handleClose={handleCloseFav}
        favBeaches={favBeaches}
      />
    </div>
  );
}
