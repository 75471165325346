import React, { useEffect, useState } from "react";
import "../../assets/css/main.css";
import { Drawer } from "@material-ui/core";
import { Outlet } from "react-router-dom";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useSelector, useDispatch } from "react-redux";
import { resetUser } from "../../shared/redux/reducers/userSlie";
import Logo from "../../assets/images/logo.png";
// import newLogo from "../../assets/images/newLogo.jpg";
import newLogo from "../../assets/images/logoNew.svg";
import Addblockagain from "../../shared/components/users/Addblockagain";
import MenuIcon from "@mui/icons-material/Menu";
import useWindowDimensions from "../../shared/utils/webDimension";
import EditProfile from "./../../shared/components/modals/editProfile";

//icons
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import ManageAccountsSharpIcon from "@mui/icons-material/ManageAccountsSharp";
import AssessmentIcon from "@mui/icons-material/Assessment";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ReportIcon from "@mui/icons-material/Report";
import CloudIcon from "@mui/icons-material/Cloud";
import ForumIcon from "@mui/icons-material/Forum";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import CommentsDisabledIcon from "@mui/icons-material/CommentsDisabled";
import GroupsIcon from "@mui/icons-material/Groups";
import SmsIcon from "@mui/icons-material/Sms";
import { handleLogOut } from "../../shared/services/Login_api";

function Home() {
  const dispatch = useDispatch();
  const location = useLocation();
  const count = useSelector((state) => state?.user?.user);
  const [drawerr, setDrawerr] = useState(false);
  const [hover, setHover] = useState("Users");
  const [loader3, setLoader3] = useState(false);
  const [sidebar, setSideBar] = useState(false);
  const { height, width } = useWindowDimensions();
  const [open, setOpen] = useState(false);

  const drawerr1 = () => {
    setDrawerr(true);
  };
  const backLogout = () => {
    handleLogOut()
      .then()
      .catch()
      .finally(() => {
        dispatch(resetUser());
      });
  };
  const [account, setAccount] = useState(false);
  const logout = () => {
    if (account) {
      setAccount(false);
    } else {
      setAccount(true);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="home">
      <div className="inner">
        <Drawer
          variant={width > 1000 ? "permanent" : ""}
          open={sidebar}
          anchor="left"
          className="levell"
          xs={20}
          onClose={() => setSideBar(false)}
          style={{ cursor: "pointer" }}
        >
          <div className="bashboard">
            <div
              className="logo"
              onClick={() => {
                if (width < 1000) {
                  setSideBar(false);
                }
              }}
            >
              <Link
                to="/dashboard/dash"
                className="lgoo"
                style={{ alignItems: "center", marginLeft: "-20px" }}
              >
                {/* <div>
                  <img src={newLogo}></img>
                </div>
                <h4>
                  <span>DAWN</span>
                  PATROL
                </h4> */}
                <div className="logoDiv">
                  <div>
                    <img src={newLogo}></img>
                  </div>
                  <div>
                    <span className="brandName">FROTH</span>
                    <span
                      className="brandNameRegular"
                      style={{ fontWeight: "400" }}
                    >
                      {/* TH */}
                    </span>
                  </div>
                </div>
              </Link>
            </div>

            <div className="listing">
              <ul
                className="dashboardUl"
                onClick={() => {
                  if (width < 1000) {
                    setSideBar(false);
                  }
                }}
              >
                {count?.admin?.role === "superAdmin" && (
                  <Link to="/dashboard/adminlist">
                    {" "}
                    <li
                      className={
                        location.pathname == "/dashboard/adminlist"
                          ? "hoveron"
                          : ""
                      }
                    >
                      <ManageAccountsSharpIcon
                        style={{ marginRight: "10px" }}
                      />
                      Admins
                    </li>
                  </Link>
                )}
                <Link to="/dashboard/userstable">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/userstable"
                        ? "hoveron"
                        : ""
                    }
                  >
                    <SupervisorAccountIcon style={{ marginRight: "10px" }} />
                    Users
                  </li>
                </Link>
                <Link to="/dashboard/reporttable">
                  <li
                    className={
                      location.pathname == "/dashboard/reporttable"
                        ? "hoveron"
                        : ""
                    }
                  >
                    <AssessmentIcon style={{ marginRight: "10px" }} />
                    Beach Reports
                  </li>
                </Link>
                <Link to="/dashboard/producttable">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/producttable"
                        ? "hoveron"
                        : ""
                    }
                  >
                    <LocalMallIcon style={{ marginRight: "10px" }} />
                    Products
                  </li>
                </Link>
                <Link to="/dashboard/tags">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/tags" ? "hoveron" : ""
                    }
                  >
                    <LocalOfferIcon style={{ marginRight: "10px" }} />
                    Search Tags
                  </li>
                </Link>
                <Link to="/dashboard/feedReport">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/feedReport"
                        ? "hoveron"
                        : ""
                    }
                  >
                    <ReportIcon style={{ marginRight: "10px" }} />
                    Reported Profiles
                  </li>
                </Link>
                <Link to="/dashboard/reportedUser">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/reportedUser"
                        ? "hoveron"
                        : ""
                    }
                  >
                    <CommentsDisabledIcon style={{ marginRight: "10px" }} />
                    Reported Chats
                  </li>
                </Link>
                <Link to="/dashboard/weather">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/weather" ? "hoveron" : ""
                    }
                  >
                    <CloudIcon style={{ marginRight: "10px" }} />
                    Weather
                  </li>
                </Link>
                <Link to="/dashboard/chat">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/chat" ? "hoveron" : ""
                    }
                  >
                    <ForumIcon style={{ marginRight: "10px" }} />
                    Chat
                  </li>
                </Link>
                <Link to="/dashboard/favouriteBeaches">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/favouriteBeaches"
                        ? "hoveron"
                        : ""
                    }
                  >
                    <GroupsIcon style={{ marginRight: "10px" }} />
                    Fav. Beaches
                  </li>
                </Link>
                <Link to="/dashboard/groups">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/groups" ? "hoveron" : ""
                    }
                  >
                    <GroupsIcon style={{ marginRight: "10px" }} />
                    Groups
                  </li>
                </Link>
                {/* <Link to="/dashboard/testimonial">
                  {" "}
                  <li
                    className={
                      location.pathname == "/dashboard/testimonial" ? "hoveron" : ""
                    }
                  >
                    <SmsIcon style={{ marginRight: "10px" }} />
                    Testimonial
                  </li>
                </Link> */}
              </ul>
            </div>
          </div>
        </Drawer>

        <div className="top_bar" style={{ paddingRight: "15px" }}>
          {/* <div className="uperbar">
            <button onClick={drawerr1}>
              <PowerSettingsNewIcon />
            </button>
          </div> */}
          <div onClick={() => setSideBar(!sidebar)}>
            <MenuIcon />
          </div>
          <div className="uperbar1 ">
            {count?.admin?.role === "superAdmin" ? (
              <button
                className="editProfileButton"
                onClick={() => handleClickOpen()}
              >
                {count?.admin?.firstName + " " + count?.admin?.lastName}
              </button>
            ) : (
              "Admin"
            )}

            {/* {count?.admin?.firstName}&nbsp;{count?.admin?.lastName} */}
            <button
              className="logoutt-btn"
              onClick={() => {
                setLoader3(true);
              }}
            >
              <PowerSettingsNewIcon />
            </button>
          </div>
        </div>
        {loader3 && (
          <Addblockagain logout backLogout={backLogout} close={setLoader3} />
        )}

        <div className={width > 1000 ? "Main_content" : "mainContent"}>
          <Outlet />
        </div>
        <EditProfile open={open} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default Home;
