import { HTTP_CLIENT } from "../utils/interceptor";
import { Endpoint_LoginApi } from "../utils/endpoint";
import axios from "axios";

const LoginApi = async (obj) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(obj);
  return HTTP_CLIENT.post(Endpoint_LoginApi.LoginApi, body, config);
};

const handleLogOut = () => {
  return HTTP_CLIENT.put(`${Endpoint_LoginApi.LogoutApi}`);
};
export { LoginApi, handleLogOut };
