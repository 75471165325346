import Dialog from "@material-ui/core/Dialog";
import CancelIcon from "@mui/icons-material/Cancel";
import { React, useEffect, useRef, useState } from "react";
import ScrollView from "react-inverted-scrollview";
import ProgressiveImage from "react-progressive-graceful-image";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import Loadering from "../../../assets/images/loading.gif";
import DefaultGroupAvatar from "../../../assets/images/mountain.png";
import NoSearch from "../../../assets/images/noSearch.png";
import { addGroupMessage, getGroupChat } from "../../services/groupsApi";
import { notify } from "../../utils/notify";
import { Message } from "./message";
import Popup from "./msgActionPopUp";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Box, CircularProgress } from "@mui/material";

function ChatView({ data, onClose }) {
  const user = useSelector((state) => state.user.user);
  const [open, setOpen] = useState(true);
  const [loader, setLoader] = useState(true);
  const [chatMsg, setChatMsg] = useState("");
  const [sendingMsg, setSendingMsg] = useState(false);
  const [modalInfo, setModalInfo] = useState({
    msg: null,
    open: false,
  });
  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };
  const [messages, setMessages] = useState([]);
  const [loadMore, setLoadMore] = useState(false);
  const pageNo = useRef(1);
  const hasMore = useRef(true);
  const InitialId = useRef(null);

  useEffect(() => {
    getData();
  }, []);

  console.log("hello", messages);
  const getData = () => {
    getGroupChat(data?._id, pageNo.current)
      .then(({ data }) => {
        if (data?.status == 200) {
          let processed = [];
          const temp = data?.data;
          if (temp.length < 10) {
            hasMore.current = false;
          }

          if (pageNo.current == 1) {
            InitialId.current =
              temp?.[0]?.senderId?.[0]?._id || temp?.[0]?.user?.[0]?._id;
          }
          temp?.map((tmp) => {
            if (tmp?.source == "Messages") {
              let msgPosition = "right";

              if (tmp?.senderId?.[0]?._id != InitialId.current)
                msgPosition = "left";
              let message = {
                ...tmp,
                _id: tmp?._id,
                text: tmp?.text,
                position: msgPosition,
              };

              processed = [message, ...processed];
            } else if (tmp?.source == "GroupReports") {
              let msgPosition = "right";
              if (tmp?.user?.[0]?._id != InitialId.current)
                msgPosition = "left";

              const dataTemp = {
                ...tmp,
                isReport: true,
                position: msgPosition,
              };
              processed = [dataTemp, ...processed];
            }
          });
          setMessages((pre) => {
            return [...processed, ...pre];
          });
        } else {
          handleClose();
          notify("Something went wrong!");
        }
      })
      .catch(() => {
        handleClose();
        notify("Something went wrong!");
      })
      .finally(() => {
        setLoadMore(false);
        setLoader(false);
      });
  };
  const LoadMoreMsgs = (pageNo) => {
    setLoadMore(true);
    getData();
  };
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="md"
      >
        <div style={{ minHeight: "80vh" }}>
          <div className="group-chat-header">
            <ProgressiveImage
              src={data?.coverPhoto ? data?.coverPhoto : DefaultGroupAvatar}
              placeholder={Loaadings}
            >
              {(src, loading) => (
                <img
                  className={`image${loading ? " loading" : " loaded"}`}
                  src={src}
                  alt={Avatar}
                  width={"100%"}
                  style={{
                    borderRadius: "25px",
                    width: "50px",
                    height: "50px",
                  }}
                />
              )}
            </ProgressiveImage>
            <div className="group-chat-text-div">
              <p className="group-edit-heading">{data?.title}</p>
            </div>
            <div className="pointer" onClick={handleClose}>
              <CancelIcon />
            </div>
          </div>
          {loader ? (
            <div className="member-loading-div">
              <div className="loaadds">
                <img src={Loadering} className="loaderr" />
              </div>
            </div>
          ) : (
            <>
              {messages?.length > 0 ? (
                <ScrollView
                  width={"100%"}
                  height={"62vh"}
                  onScroll={({ scrollTop, scrollBottom }) => {
                    if (scrollTop < 80) {
                      if (!loadMore && !!hasMore.current) {
                        pageNo.current++;
                        LoadMoreMsgs(pageNo.current);
                      }
                    }
                  }}
                  style={{
                    padding: "0px 20px",
                    borderTop: "1px solid #e3e3e3",
                  }}
                >
                  {loadMore && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="loaadds">
                        <img
                          src={Loadering}
                          className="loaderr"
                          style={{ width: "100px" }}
                        />
                      </div>
                    </div>
                  )}
                  {messages?.map((item, inx) => {
                    return (
                      <Message
                        item={item}
                        previousMsg={inx > 0 ? messages[inx - 1] : null}
                        inx={inx}
                        onPress={(item) => {
                          setModalInfo({
                            msg: item,
                            open: true,
                          });
                        }}
                      />
                    );
                  })}
                </ScrollView>
              ) : (
                <div className="member-loading-div">
                  <img className="no-search" src={NoSearch} />
                  <p className="users-error-text" style={{ marginTop: "10px" }}>
                    No Messages Found!
                  </p>
                </div>
              )}
              {modalInfo?.open && (
                <Popup
                  MsgObj={modalInfo.msg}
                  handleClose={() => {
                    setModalInfo({
                      msg: null,
                      open: false,
                    });
                  }}
                  onDelete={() => {
                    setMessages((pre) => {
                      let msgs = pre?.filter(
                        (elem) => elem?._id != modalInfo?.msg?._id
                      );
                      return msgs;
                    });
                  }}
                  onEdit={(item) => {
                    setMessages((pre) => {
                      let msgs = pre?.map((elem) => {
                        if (elem?._id == modalInfo?.msg?._id) {
                          return item;
                        } else return elem;
                      });
                      return msgs;
                    });
                  }}
                />
              )}
            </>
          )}
          <div className="msg-cont">
            <div className="text-cont">
              <input
                type="search"
                placeholder="message..."
                value={chatMsg}
                onChange={(e) => {
                  setChatMsg(e.target.value);
                }}
                autoComplete="off"
              ></input>
            </div>
            <div style={{ marginLeft: "10px", marginRight: "10px" }}>
              <Button
                className="add-member-btn"
                variant="contained"
                onClick={() => {
                  setSendingMsg(true);
                  let msgData = {
                    msgId: `${new Date().getTime()}`,
                    senderId: user?.admin?.id,
                    groupId: data?._id,
                    text: chatMsg,
                    createdAt: new Date(),
                  };
                  addGroupMessage(msgData)
                    .then(({ data }) => {
                      if (data.status == "200") {
                        let newMsg = {
                          _id: msgData.msgId,

                          senderId: [
                            {
                              _id: msgData.senderId,
                              firstName: user?.admin.firstName,
                              lastName: user?.admin.lastName,
                              email: user?.admin.email,
                            },
                          ],
                          text: msgData.text,

                          createdAt: msgData.createdAt,
                          position:
                            InitialId.current == msgData.senderId
                              ? "right"
                              : "left",
                        };
                        setMessages((pre) => {
                          return [...pre, newMsg];
                        });
                        setChatMsg("");
                      }
                    })
                    .catch(() => {
                      notify("Something went wrong");
                    })
                    .finally(() => {
                      setSendingMsg(false);
                    });
                }}
              >
                {sendingMsg ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                ) : (
                  <>Send</>
                )}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default ChatView;
