import { Button } from "@material-ui/core";
import ChatIcon from "@mui/icons-material/Chat";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Pagination } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { useSelector } from "react-redux";
import XLSX from "xlsx";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import Loadering from "../../../assets/images/loading.gif";
import DefaultGroupAvatar from "../../../assets/images/mountain.png";
import { deleteGroup, getAllUsers, getGroups } from "../../services/groupsApi";
import { notify } from "../../utils/notify";
import { useDebounce } from "../../utils/useDebounce";
import { useFilteredPagination } from "../../utils/useFilteredPagination";
import ChatView from "../groups/chatView";
import DeleteGroupModal from "../groups/deleteModal";
import GroupUpdate from "../groups/editModal";
import GroupView from "../groups/groupView";

function Groups() {
  const user = useSelector((state) => state.user.user);
  const [searchMenu, setsearchMenu] = useState("");
  const [showGroupDetail, setShowGroupDetail] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentSelected, setCurrentSelected] = useState(null);
  const [showGroupEditModal, setShowGroupEditModal] = useState(false);
  const [showGroupAddModal, setShowGroupAddModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [state, setState, hitApi, handleFiltersRemoved] = useFilteredPagination(
    getGroups,
    [["search", ""]],
    (data) => {
      return {
        count: data.count,
        data: data.groups,
      };
    }
  );
  useEffect(() => {
    hitApi({ search: "" }, 1);
    getAllUsers().then(({ data }) => {
      setAllUsers(data?.data);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setState((pre) => {
      return {
        ...pre,
        renderedCurrentPage: newPage,
      };
    });
    hitApi({ search: searchMenu }, newPage);
  };

  const ViewGroup = (data) => {
    setCurrentSelected(data);
    setShowGroupDetail(true);
  };

  const DeleteGroup = (data) => {
    setCurrentSelected(data);
    setShowDeleteModal(true);
  };

  const UpdateGroup = (data) => {
    setCurrentSelected(data);
    setShowGroupEditModal(true);
  };

  const AddGroup = (data) => {
    setCurrentSelected(data);
    setShowGroupAddModal(true);
  };

  const ViewChat = (data) => {
    setCurrentSelected(data);
    setShowChatModal(true);
  };

  const downloadExcel = () => {
    let downloadData = [];
    state?.data?.map((item, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Title: item?.title,
        Description: item?.description,
      };
      tempObj["Cover Photo"] = item?.coverPhoto;
      tempObj["Members Count"] = item?.members?.length;
      downloadData.push(tempObj);
    });

    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Products");

    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });

    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });

    XLSX.writeFile(workBook, "Groups Data.xlsx");
  };
  useDebounce(
    () => {
      if (searchMenu != "") {
        hitApi({ search: searchMenu }, 1);
      } else {
        handleFiltersRemoved();
      }
    },
    [searchMenu],
    500
  );

  return (
    <div className="users_table">
      <div className="titlee">
        <h2>Groups List</h2>
      </div>
      <div className="search_view">
        <input
          type="search"
          placeholder="Search..."
          value={searchMenu}
          onChange={(e) => {
            setsearchMenu(e.target.value);
          }}
          autoComplete="off"
        ></input>
      </div>
      {state.mainLoader ? (
        <div className="loaadds">
          <img src={Loadering} className="loaderr" />
        </div>
      ) : (
        <>
          <div className="excle_btn">
            <Button
              className="dowloadButton"
              onClick={() => {
                downloadExcel();
              }}
            >
              Download Groups Data <FileDownloadIcon />
            </Button>
            <Button
              variant="contained"
              color="success"
              className="addAdminBtn"
              onClick={() => {
                AddGroup();
              }}
            >
              Add Group
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="tableRowData">
                  <TableCell className="tableHeadings" align="center">
                    Sr
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Cover
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Group Title
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Members
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Chat
                  </TableCell>
                  <TableCell
                    className="tableHeadings"
                    align="center"
                    colSpan={4}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.data.map((dats, i) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center">
                          {(state.renderedCurrentPage - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell align="center">
                          <ProgressiveImage
                            src={
                              dats?.coverPhoto
                                ? dats?.coverPhoto
                                : DefaultGroupAvatar
                            }
                            placeholder={Loaadings}
                          >
                            {(src, loading) => (
                              <img
                                className={`profille image${
                                  loading ? " loading" : " loaded"
                                }`}
                                src={src}
                                alt={Avatar}
                              />
                            )}
                          </ProgressiveImage>
                        </TableCell>
                        <TableCell align="center">{dats?.title} </TableCell>

                        <TableCell align="center">
                          {dats?.members?.length}
                        </TableCell>
                        <TableCell align="center">
                          <button
                            className="bttn btn-success"
                            type="button"
                            onClick={() => {
                              ViewChat(dats);
                            }}
                          >
                            <ChatIcon />
                          </button>
                        </TableCell>
                        <TableCell align="center">
                          <button
                            className="bttn btn-success"
                            type="button"
                            onClick={() => {
                              ViewGroup(dats);
                            }}
                          >
                            <VisibilityIcon />
                          </button>
                        </TableCell>
                        <TableCell align="center">
                          <button
                            className={"bttn btn-danger"}
                            type="button"
                            onClick={() => {
                              DeleteGroup(dats);
                            }}
                          >
                            <DeleteForeverSharpIcon />
                          </button>
                        </TableCell>
                        <TableCell align="center">
                          <button
                            onClick={() => {
                              UpdateGroup(dats);
                            }}
                            className="bttn btn btnupdate"
                            type="button"
                          >
                            <EditSharpIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {showChatModal && (
              <ChatView
                data={currentSelected}
                onClose={() => {
                  setCurrentSelected(null);
                  setShowChatModal(false);
                }}
              />
            )}
            {showGroupDetail && (
              <GroupView
                data={currentSelected}
                onClose={() => {
                  setCurrentSelected(null);
                  setShowGroupDetail(false);
                }}
              />
            )}
            {showDeleteModal && (
              <DeleteGroupModal
                data={currentSelected}
                onClose={() => {
                  setCurrentSelected(null);
                  setShowDeleteModal(false);
                }}
                onDelete={(item) => {
                  deleteGroup(item?._id)
                    .then(() => {
                      notify("Group deleted successfully!", "success");
                      setState((pre) => {
                        return {
                          ...pre,
                          data: pre.data?.filter(
                            (elem) => elem?._id != item?._id
                          ),
                          mainData: pre.mainData?.filter(
                            (elem) => elem?._id != item?._id
                          ),
                        };
                      });
                    })
                    .catch((err) => {
                      notify("Something went wrong!");
                    });
                }}
              />
            )}
            {showGroupEditModal && (
              <GroupUpdate
                edit={true}
                title={"Edit Group"}
                onClose={() => {
                  setShowGroupEditModal(false);
                  setCurrentSelected(null);
                }}
                data={currentSelected}
                allUsers={allUsers}
                setAllUsers={setAllUsers}
                onEdit={(data) => {
                  console.log("hello", data);
                  setState((pre) => {
                    return {
                      ...pre,
                      data: pre.data?.map((elem) => {
                        if (elem?._id == data?._id) {
                          return data;
                        } else return elem;
                      }),
                      mainData: pre.mainData?.map((elem) => {
                        if (elem?._id == data?._id) {
                          return data;
                        } else return elem;
                      }),
                    };
                  });
                }}
              />
            )}
            {showGroupAddModal && (
              <GroupUpdate
                edit={false}
                title={"Add Group"}
                onClose={() => {
                  setShowGroupAddModal(false);
                  setCurrentSelected(null);
                }}
                data={currentSelected}
                allUsers={allUsers}
                setAllUsers={setAllUsers}
              />
            )}
          </TableContainer>
          <div className="paginationContainer">
            <Pagination
              count={state.renderedLastPage}
              page={state.renderedCurrentPage}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiPaginationItem-root": {
                  height: "50px",
                  width: "50px",
                  fontSize: "1rem",
                },
                "& .Mui-selected": {
                  backgroundColor: "#488fbf !important",
                  color: "white",
                  border: "none",
                },
              }}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Groups;
