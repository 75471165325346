import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { React, useEffect, useState } from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import DefaultGroupAvatar from "../../../assets/images/mountain.png";
import MemberCard from "./memberCard";
import { Button } from "@material-ui/core";
import { getAllGroupRequests, manageRequest } from "../../services/groupsApi";
import Loadering from "../../../assets/images/loading.gif";
import { notify } from "../../utils/notify";
import { Box, CircularProgress } from "@mui/material";
import { ROLES } from "../../utils/constant";

function GroupView({ data, onClose }) {
  const [open, setOpen] = useState(true);
  const [showRequests, setShowRequests] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };
  const shouldShowRequests =
    data?.adminId?.role == ROLES.ADMIN ||
    data?.adminId?.role == ROLES.SUPER_ADMIN;
  const hitGroupRequestsApi = (id) => {
    getAllGroupRequests(id)
      .then(({ data }) => {
        if (data?.data?.length > 0) {
          setRequests(data?.data);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (shouldShowRequests) {
      hitGroupRequestsApi(data?._id);
    }
  }, []);
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <IconButton aria-label="close" onClick={handleClose}>
          <CancelIcon />
        </IconButton>
        <div class="scrollbar scrollbar-lady-lips">
          <div class="force-overflow">
            <div className="lelel">
              <div className="group-view-header">
                <p className="group-view-heading">
                  {showRequests ? "Join Requests" : "Group Details"}
                </p>
                {shouldShowRequests && (
                  <Button
                    className="add-member-btn"
                    variant="contained"
                    onClick={() => {
                      setShowRequests((pre) => !pre);
                    }}
                  >
                    {showRequests ? "Group Details" : "Join Requests"}
                  </Button>
                )}
              </div>
              {showRequests ? (
                <div className="requests-cont">
                  {loading ? (
                    <div className="loaadds">
                      <img src={Loadering} className="loaderr" />
                    </div>
                  ) : (
                    <>
                      {requests?.map((item) => {
                        return (
                          <RequestCard
                            item={item}
                            filterRequests={(id) => {
                              setRequests((pre) => {
                                return pre.filter(
                                  (singleReq) => singleReq?.id != id
                                );
                              });
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                </div>
              ) : (
                <div className="flexxview">
                  <div className="group">
                    <ProgressiveImage
                      src={
                        data.coverPhoto ? data.coverPhoto : DefaultGroupAvatar
                      }
                      placeholder={Loaadings}
                    >
                      {(src, loading) => (
                        <img
                          className={`image${loading ? " loading" : " loaded"}`}
                          src={src}
                          alt={Avatar}
                          width={"100%"}
                          style={{
                            borderRadius: "2px",
                            width: "140px",
                            height: "140px",
                            marginBottom: "50px",
                          }}
                        />
                      )}
                    </ProgressiveImage>
                  </div>
                  <div className="detaill">
                    <div className="reportview">
                      <h6 className="popUpHeading">Group Title</h6>
                      <p className="popUpDetail">{data.title}</p>
                    </div>
                    <hr />
                    <div className="reportview">
                      <h6 className="popUpHeading">Description</h6>
                      <p className="popUpDetail">{data.description}</p>
                    </div>
                    <hr />
                    <div className="reportview">
                      <h6 className="popUpHeading">Creator</h6>
                      <p className="popUpDetail">
                        {data?.adminId?.firstName +
                          " " +
                          data?.adminId?.lastName}
                      </p>
                    </div>
                    <div className="reportview">
                      <h6 className="popUpHeading">Creator Email</h6>
                      <p className="popUpDetail">{data?.adminId?.email}</p>
                    </div>
                    <hr />
                    <div className="reportview">
                      <h6 className="popUpHeading">Members Count</h6>
                      <p className="popUpDetail">{data?.members?.length}</p>
                    </div>
                    <hr />
                    <div className="reportview">
                      <h6 className="popUpHeading">Members List</h6>
                    </div>
                    {data?.members?.map((item, index) => {
                      return <MemberCard item={item} />;
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

const RequestCard = ({ item, filterRequests }) => {
  const [loading, setLoading] = useState(null);
  const manageUserRequest = async (accept) => {
    setLoading(accept ? "accept" : "reject");

    await manageRequest(item?.id, accept)
      .then(async (response) => {
        notify(
          `Request ${accept ? "accepted" : "rejected"} successfully!`,
          "success"
        );
        filterRequests(item?.id);
      })
      .catch((error) => {
        notify(`Something went wrong`);
      })
      .finally(() => setLoading(null));
  };
  return (
    <div className="request-card-cont">
      <div className="request-card-image-cont">
        <ProgressiveImage
          src={
            item?.userId?.profilePic
              ? item?.userId?.profilePic
              : DefaultGroupAvatar
          }
          placeholder={Loaadings}
        >
          {(src, loading) => (
            <img
              className={`profille image${loading ? " loading" : " loaded"}`}
              src={src}
              alt={Avatar}
            />
          )}
        </ProgressiveImage>
        <p className="member-card-name">
          {item?.userId?.firstName + " " + item?.userId?.lastName}
        </p>
      </div>
      <div>
        <Button
          className="accept-request-btn"
          variant="contained"
          disabled={!!loading}
          onClick={() => {
            manageUserRequest(true);
          }}
        >
          {loading == "accept" ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : (
            "Accept"
          )}
        </Button>
        <Button
          className="reject-request-btn"
          variant="contained"
          disabled={!!loading}
          onClick={() => {
            manageUserRequest(false);
          }}
        >
          {loading == "reject" ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress color="inherit" size={20} />
            </Box>
          ) : (
            "Reject"
          )}
        </Button>
      </div>
    </div>
  );
};
export default GroupView;
