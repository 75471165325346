import ProgressiveImage from "react-progressive-graceful-image";
import Loaadings from "../../../assets/images/ImageLoad.gif";
import Avatar from "../../../assets/images/avatar.png";
import GroupProfileIcon from "../../../assets/images/group_profile_icon.png";
import HeightIcon from "../../../assets/images/arrow.png";
import WaveIcon from "../../../assets/images/wave-1.png";
import KookIcon from "../../../assets/images/kookalert1.png";
import SharkIcon from "../../../assets/images/sharkalert1.png";
import CrowdIcon from "../../../assets/images/crowdAlert1.png";
import LocIcon from "../../../assets/images/loc.png";
import BackgroundImg from "../../../assets/images/wave_icon.jpeg";

function Post({ item }) {
  return (
    <div className={item?.position == "right" ? "leftChat rightt" : "leftChat"}>
      {item?.position == "left" && (
        <div className="chatimg" style={{ marginRight: "10px" }}>
          {item?.user?.[0]?.profilePic ? (
            <div style={{ position: "relative" }}>
              <ProgressiveImage
                src={item?.user?.[0]?.profilePic}
                placeholder={Loaadings}
              >
                {(src, loading) => (
                  <img
                    className={`image${loading ? " loading" : " loaded"}`}
                    src={src}
                    alt={Avatar}
                  />
                )}
              </ProgressiveImage>
            </div>
          ) : (
            <img className="image loaded" src={GroupProfileIcon} />
          )}
        </div>
      )}
      <div
        className="break-post-main-div"
        style={{
          backgroundImage: `url(${item?.images?.[0] || BackgroundImg})`,
          minWidth: "300px",
        }}
      >
        <div className="break-post-child-div" />
        <div className="break-post-child-child-div">
          <div className="break-post-header-div">
            <div className="flex-row">
              <div className="member-card-profile">
                <ProgressiveImage
                  src={item?.user?.[0]?.profilePic || GroupProfileIcon}
                  placeholder={Loaadings}
                >
                  {(src, loading) => (
                    <img
                      className={`image${loading ? " loading" : " loaded"}`}
                      src={src}
                      alt={item?.user?.[0]?.profilePic}
                      width={"100%"}
                      style={{
                        borderRadius: "2px",
                        width: "140px",
                        height: "140px",
                        marginBottom: "50px",
                      }}
                    />
                  )}
                </ProgressiveImage>
              </div>
              <h3 className="post-header-name">
                {item?.user?.[0]?.firstName + " " + item?.user?.[0]?.lastName}
              </h3>
            </div>
            <div className="flex-row">
              {item?.kookAlert && <img className="alert-icon" src={KookIcon} />}
              {item?.crowdAlert && (
                <img className="alert-icon" src={CrowdIcon} />
              )}
              {item?.sharkAlert && (
                <img className="alert-icon" src={SharkIcon} />
              )}
            </div>
          </div>
          <div className="break-post-row-div">
            <img className="report-icon" src={HeightIcon} />
            <p className="post-detail-text">
              Predicted Wave Height:{" "}
              {Number(item?.beachId?.[0]?.waveSize)?.toFixed(0)}
            </p>
          </div>
          <div className="break-post-row-div" style={{}}>
            <img className="report-icon" src={WaveIcon} />
            <p className="post-detail-text">
              Predicted Wave Form: {item?.beachId?.[0]?.waveForm}
            </p>
          </div>
          <div className="break-post-row-div" style={{}}>
            <img className="report-icon" src={LocIcon} />
            <p className="post-detail-text">{item?.locationTitle}</p>
          </div>
          <div
            className="break-post-row-div"
            style={{ paddingLeft: "0px", marginLeft: 0 }}
          >
            <p
              className="post-detail-text"
              style={{ paddingLeft: "0px", marginLeft: 0 }}
            >
              "{item?.description}"
            </p>
          </div>
          <div className="break-post-row-div" style={{ paddingLeft: "0px" }}>
            <p className="post-date-text">Posted: {timeAgo(item?.createdAt)}</p>
          </div>
        </div>
      </div>
      {item?.position == "right" && (
        <div className="chatimg rightImg">
          {item?.user?.[0]?.profilePic ? (
            <ProgressiveImage
              src={item?.user?.[0]?.profilePic}
              placeholder={Loaadings}
            >
              {(src, loading) => (
                <img
                  className={`image${loading ? " loading" : " loaded"}`}
                  src={src}
                  alt={Avatar}
                />
              )}
            </ProgressiveImage>
          ) : (
            <img className="image loaded" src={GroupProfileIcon} />
          )}
        </div>
      )}
    </div>
  );
}

function timeAgo(inputDate) {
  const now = new Date();
  const pastDate = new Date(inputDate);
  const secondsDiff = Math.floor((now - pastDate) / 1000);

  if (secondsDiff < 60) {
    return `${secondsDiff} second${secondsDiff === 1 ? "" : "s"} ago`;
  }

  const minutesDiff = Math.floor(secondsDiff / 60);
  if (minutesDiff < 60) {
    return `${minutesDiff} minute${minutesDiff === 1 ? "" : "s"} ago`;
  }

  const hoursDiff = Math.floor(minutesDiff / 60);
  if (hoursDiff < 24) {
    return `${hoursDiff} hour${hoursDiff === 1 ? "" : "s"} ago`;
  }

  const daysDiff = Math.floor(hoursDiff / 24);
  if (daysDiff < 30) {
    return `${daysDiff} day${daysDiff === 1 ? "" : "s"} ago`;
  }

  const monthsDiff = Math.floor(daysDiff / 30);
  if (monthsDiff < 12) {
    return `${monthsDiff} month${monthsDiff === 1 ? "" : "s"} ago`;
  }

  const yearsDiff = Math.floor(monthsDiff / 12);
  return `${yearsDiff} year${yearsDiff === 1 ? "" : "s"} ago`;
}

export default Post;
