import { Button } from "@material-ui/core";
import DeleteForeverSharpIcon from "@mui/icons-material/DeleteForeverSharp";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SortIcon from "@mui/icons-material/Sort";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Checkbox, Pagination } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import XLSX from "xlsx";
import Caution from "../../../assets/images/caution.jpg";
import Loadering from "../../../assets/images/loading.gif";
import AddReport from "../../../shared/components/modals/addReport";
import {
  CreateReportAPI,
  deleteAllSelectedReports,
  ReportapiData,
} from "../../services/ReportApi";
import { notify } from "../../utils/notify";
import { useDebounce } from "../../utils/useDebounce";
import FormDialog from "../dialog";
import ViewedPopUp from "../products/viewedPopUp";
import AddReportagain from "./AddReportagain";
import DeleteReport from "./DeleteReport";
import ReportBlock from "./ReportBlock";
import ReportUpdate from "./ReportUpdate";
import ReportView from "./ReportView";

export default function ReportsTable(props, { columns, data }) {
  const [deleteAllBool, setDeleteAllBool] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [UserCount, setUserCount] = useState(1);
  const [openAddReport, setOpenAddReport] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);
  const handleAddReport = () => setOpenAddReport(true);
  const handleCloseReport = () => setOpenAddReport(false);
  const [dataUser, setdataUser] = useState([]);
  const [netcheckk, setNetcheckk] = useState(false);
  const [respchange, setrespchange] = useState(false);
  const [open, setOpen] = useState(props.open);
  const [loader, setLoader] = useState(false);
  const [viewResp, setvewiResp] = useState(null);
  const [loader3, setLoader3] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [loader4, setLoader4] = useState(false);
  const [loader5, setLoader5] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [delId, setdelId] = useState();
  const [addId, setaddId] = useState();
  const [updated, setUpdated] = useState(false);
  const [searchMenu, setsearchMenu] = useState("");
  const [searchValue, setsearchValue] = useState("");
  const [change, setChange] = useState(false);
  const [sortedField, setSortedField] = React.useState(null);
  let sortedProducts = [...dataUser];
  const [fileterbool, setFilterBool] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [fdate, setFdate] = useState("");
  const [lDate, setLdate] = useState("");
  const [reviewedOn, setReviewedOn] = useState(false);

  const [state, setState] = useStateWithCallbackLazy({
    pgNo: 1,
    perPg: 10,
    loader: true,
  });
  const closeModal = () => setFilterBool(false);
  const downloadData = [];

  const updatestate = (message) => {
    setUpdated((prev) => !prev);
    notify(`${message}`, "success");
  };
  const handleupdate = (id, obj, img) => {
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id);
    changing[findelement].title = obj.title;
    changing[findelement].description = obj.description;
    changing[findelement].waveSize = obj.waveSize;
    changing[findelement].waveForm = obj.waveForm;
    changing[findelement].images = img;

    setdataUser(changing);
  };

  const handleDelete = (id) => {
    const index = sortedProducts.findIndex((i) => i._id == id);
    if (index > -1) {
      let temp = [...dataUser];
      temp.splice(index, 1);
      setdataUser(temp);
    }
  };

  const handleblocked = (id_block) => {
    let statusblock;
    let changing = [...dataUser];
    let findelement = changing.findIndex((ii) => ii._id == id_block);
    if (changing[findelement].isBlocked == true) {
      statusblock = false;
    } else {
      statusblock = true;
    }
    changing[findelement].isBlocked = statusblock;
    setdataUser(changing);
  };
  const viewdata = (resp) => {
    if (resp?._id && user?.admin?.role == "admin") {
      setLoader(true);
      setvewiResp(resp);
    } else {
      setLoader(true);
      setvewiResp(resp);
    }
  };

  const Userupdated = (resp) => {
    setLoader4(true);
    setaddId(resp);
  };
  const deletereport = (id) => {
    setLoader1(true);
    setdelId(id);
  };
  const netcheck = () => {
    if (!window.navigator.onLine) {
      notify("Network not found");
      setOpen(false);
      setNetcheckk(true);
    }
  };
  const filterfun = () => {
    setSortedField(null);
  };
  const emptyforce = () => {
    setdataUser([]);
    setState({
      ...state,
      pgNo: 1,
    });
    // setpgNo(1)
    setUpdated((prev) => !prev);
  };
  const {
    user: { user },
  } = useSelector((state) => state);

  useEffect(() => {
    netcheck();
    let query = `?pageNo=${currentPage}&perPage=${10}${
      searchValue ? `&search=${searchValue}` : ""
    }${fdate ? `&startDate=${fdate}` : ""}${lDate ? `&endDate=${lDate}` : ""} 
    ${reviewedOn ? `&reviewed=${reviewedOn}` : ""}
    `;

    ReportapiData(query)
      .then(({ data }) => {
        setUserCount(data?.data?.count);
        // setdataUser(data?.data?.reports);
        let temp = [];
        data?.data?.reports.map((item) => {
          item.flag = false;
          item["type"] =
            item?.videos?.length > 0
              ? "Video"
              : item?.images?.length > 0
              ? "Image"
              : "Text";
          temp.push(item);
        });
        setdataUser(temp);

        setState({
          ...state,
          loader: false,
        });
        if (data) {
          setOpen(false);
        }
        if (data && data.data && data.data?.reports?.length) {
          let newww = [...dataUser];
          data.data.reports.forEach((element) => {
            let findAnyelement = newww.findIndex((ii) => ii._id == element._id);
            if (findAnyelement < 0) {
              newww = [...newww, element];
            }
          });
        }
      })
      .catch((err) => {
        setState({
          ...state,
          loader: false,
        });
      });
  }, [updated, change, currentPage, fdate, lDate, reviewedOn]);

  const downloadapi = () => {
    downloadExcel(data);
  };

  const downloadExcel = (resp) => {
    downloadData.length = 0;
    sortedProducts.map((items, index) => {
      const tempObj = {
        Sr_No: index + 1,
        Beach: items.locationTitle,
        Condition: items.waveForm,
        WaveSize: items.waveSize,
        ReportBy: items?.user?.email,
        Firstname: items?.user?.firstName,
        Lastname: items?.user?.lastName,
        Type: items?.type,
        Live_Status: items.isBlocked ? "Blocked" : "Live",
        Date: moment(items.createdAt).format("Do MMM YY"),
        Time: moment(items.createdAt).format("h:mm:ss a"),
        Review: items?.isReviewed ? "Yes" : "No",
        Reviewer: items?.reviewId
          ? items?.reviewId?.adminId?.firstName +
            " " +
            items?.reviewId?.adminId?.lastName
          : "No Review",
        Review_Date: items?.reviewId
          ? moment(items?.reviewId?.timeReviewed).format("Do MMM YY")
          : "No Review",
        Review_Time: items?.reviewId
          ? moment(items?.reviewId?.timeReviewed).format("h:mm:ss a")
          : "No Review",
      };
      downloadData.push(tempObj);
    });
    const workSheet = XLSX.utils.json_to_sheet(downloadData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Reports");
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workBook, "Reports Data.xlsx");
  };

  if (sortedField !== null) {
    console.log("hello", sortedField);
    sortedProducts.sort((a, b) => {
      if (sortedField == "firstName") {
        if (a.user[sortedField] < b.user[sortedField]) {
          return -1;
        }
        if (a.user[sortedField] > b.user[sortedField]) {
          return 1;
        }
      } else if (sortedField == "email") {
        if (a.user[sortedField] < b.user[sortedField]) {
          return -1;
        }
        if (a.user[sortedField] > b.user[sortedField]) {
          return 1;
        }
      } else {
        if (a[sortedField] < b[sortedField]) {
          return -1;
        }
        if (a[sortedField] > b[sortedField]) {
          return 1;
        }
      }
      return 0;
    });
  }
  const weatherGo = (item) => {
    Navigate("/dashboard/weather");
  };
  const FilterDate = (firstdte, lstdte) => {
    netcheck();
    setState(
      {
        ...state,
        pgNo: 1,
      },
      (va) => {
        emptyforce();
        setFdate(firstdte);
        setLdate(lstdte);
        closeModal();
        setOpen(true);
        setrespchange(true);
      }
    );
  };

  const handleSubmit = (obj) => {
    setCreateLoader(true);
    CreateReportAPI(obj)
      .then(({ data: { data, msg } }) => {
        let obj = { ...data };
        obj["_id"] = data?.id;
        let temp = [...dataUser];
        temp.unshift(obj);
        setdataUser(temp);
        sortedProducts = temp;
        notify(msg, "success");
        setUpdated((prev) => !prev);
        setCreateLoader(false);
      })
      .catch((err) => {
        notify(err?.response?.msg);
        notify(err?.response?.message);
        notify(err?.response?.data?.message);
        setCreateLoader(false);
      });
  };

  const handleChangePage = (event, newPage) => {
    setDeleteAllBool(false);
    setCurrentPage(newPage);
    // You can fetch new data based on the newPage or update the data to display.
  };

  const handleFilter = (start, end) => {
    setOpen(true);
    setCurrentPage(1);
    setFdate(start);
    setLdate(end);
    // tableWithFilter();
    // UserAPIwithFilter(currentPage, 10, start, end)
    //   .then(({ data }) => {

    //   })
    //   .catch((err) => {

    //   })
  };

  const handleCheck = (item, index) => {
    let temp = [...dataUser];
    temp[index].flag = !temp[index].flag;
    setdataUser(temp);
    checkIfAnySelected();
  };

  const checkIfAnySelected = () => {
    let bool = false;
    dataUser?.map((item) => {
      console.log(item?.flag);
      if (item?.flag) {
        bool = true;
      }
    });
    setDeleteAllBool(bool);
  };

  const handleSelectedDelete = () => {
    let arr = [];
    dataUser?.map((item) => {
      if (item?.flag) {
        arr.push(item?._id);
      }
    });

    let obj = {
      reportIds: arr,
    };

    setOpen(true);

    deleteAllSelectedReports(obj)
      .then(({ data }) => {
        notify(data?.msg, "success");
        setChange((prev) => !prev);
        setOpen(true);
        setDeleteAllBool(false);
      })
      .catch((err) => {
        notify(err?.response?.message);
        notify(err?.response?.data?.message);
        notify(err?.response?.data?.msg);
      });
  };

  useDebounce(
    () => {
      setsearchValue(searchMenu);
      emptyforce();
    },
    [searchMenu],
    800
  );

  return (
    <div className="users_table">
      <div className="titlee">
        <h2>Beach Reports List</h2>
      </div>
      <div className="search_view">
        <input
          type="search"
          placeholder="Search..."
          autoComplete="off"
          value={searchMenu}
          onChange={(e) => {
            setsearchMenu(e.target.value);
          }}
        ></input>
      </div>
      {open ? (
        <div className="loaadds">
          <img src={Loadering} className="loaderr" />
        </div>
      ) : (
        <>
          <div
            className="excle_btn"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div>
              <Button
                className="addReportButton"
                onClick={() => handleAddReport()}
              >
                Add Report
              </Button>
              {deleteAllBool && (
                <Button
                  sx={{ background: "red" }}
                  className="deleteReportButton"
                  onClick={() => handleSelectedDelete()}
                >
                  Delete Selected
                </Button>
              )}
            </div>
            <div>
              <Button
                className={
                  reviewedOn
                    ? "reviewedOnButton reviewedButton"
                    : "reviewedButton"
                }
                onClick={() => setReviewedOn(!reviewedOn)}
              >
                Reviewed only
              </Button>
              {!fdate ? (
                <Button
                  className="excle_innbtn dowloadButton filterBtn"
                  onClick={() => setFilterBool(true)}
                >
                  <FilterAltIcon />
                </Button>
              ) : (
                <Button
                  className="excle_innbtn"
                  onClick={() => {
                    setState(
                      {
                        ...state,
                        pgNo: 1,
                      },
                      () => {
                        emptyforce();
                        setFdate("");
                        setLdate("");
                        setStartDate(null);
                        setEndDate(null);
                        setrespchange(false);
                        setOpen(true);
                      }
                    );
                  }}
                >
                  <RotateLeftIcon />
                </Button>
              )}
              <Button
                className="excle_innbtn dowloadButton downloadButtonWidth"
                onClick={() => {
                  downloadapi();
                }}
              >
                {downloadLoader ? (
                  <Box sx={{ display: "flex" }}>
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                ) : (
                  <>
                    Download Reports Data <FileDownloadIcon />
                  </>
                )}
              </Button>
            </div>
          </div>
          <TableContainer component={Paper}>
            {/* <InfinitScroll
              dataLength={dataUser.length}
              next={getApiCall}
              hasMore={true}
              loader={state.loader ? <LinearProgress /> : ""}
            > */}
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className="tableRowData">
                  <TableCell className="tableHeadings" align="center">
                    Select
                  </TableCell>
                  {/* <TableCell className="tableHeadings" align="center">SR</TableCell> */}
                  <TableCell className="tableHeadings" align="center">
                    Date
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("createdAt")
                      }
                    />
                  </TableCell>
                  {/* <TableCell className="tableHeadings" align="center">
                  Title
                  <SortIcon
                    className="filterr"
                    onClick={() =>
                      sortedField ? filterfun() : setSortedField("title")
                    }
                  />
                </TableCell> */}
                  <TableCell className="tableHeadings" align="center">
                    Beach
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField
                          ? filterfun()
                          : setSortedField("locationTitle")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Email
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("email")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Time
                    <SortIcon
                      className="filterr"
                      onClick={() =>
                        sortedField ? filterfun() : setSortedField("time")
                      }
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    First Name{" "}
                    <SortIcon
                      className="filterr"
                      onClick={() => {
                        sortedField ? filterfun() : setSortedField("firstName");
                      }}
                    />
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Last Name{" "}
                  </TableCell>
                  <TableCell className="tableHeadings" align="center">
                    Review
                  </TableCell>
                  {/* {user?.admin?.role != "admin" ? (
                    <TableCell className="tableHeadings" align="center">Re-Viewed By</TableCell>
                  ) : (
                    ""
                  )} */}
                  <TableCell
                    className="tableHeadings"
                    align="center"
                    colSpan={3}
                  >
                    Item
                    <SortIcon
                      className="filterr"
                      onClick={() => {
                        sortedField ? filterfun() : setSortedField("type");
                      }}
                    />
                  </TableCell>

                  <TableCell
                    className="tableHeadings"
                    align="center"
                    colSpan={3}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts.map((dats, i) => {
                  return (
                    <>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {/* <TableCell className="tableCellData" align="center">{i + 1} </TableCell> */}
                        <TableCell>
                          {dataUser && (
                            <Checkbox
                              onChange={(e) => handleCheck(dats, i)}
                              defaultChecked={dats?.flag ? true : false}
                            />
                          )}
                        </TableCell>
                        <TableCell className="tableCellData" align="center">
                          {moment(dats.createdAt).format("Do MMM YY")}
                        </TableCell>
                        {/* <TableCell className="tableCellData" align="center">{dats.title} </TableCell> */}
                        <TableCell className="tableCellData" align="center">
                          {dats.locationTitle}
                        </TableCell>
                        {respchange ? (
                          <TableCell
                            className="tableCellData"
                            align="center"
                            sx={{ maxWidth: "fit-content" }}
                          >
                            {dats.user &&
                              dats.user.map((item, i) => {
                                return <>{item.email}</>;
                              })}
                          </TableCell>
                        ) : (
                          <TableCell
                            className="tableCellData"
                            align="center"
                            sx={{ maxWidth: "fit-content" }}
                          >
                            {dats.user && dats.user.email}
                          </TableCell>
                        )}
                        <TableCell className="tableCellData" align="center">
                          {moment(dats.createdAt).format("h:mm:ss a")}
                        </TableCell>
                        {respchange ? (
                          <TableCell className="tableCellData" align="center">
                            {dats.user &&
                              dats.user.map((item, i) => {
                                return <>{item.firstName}</>;
                              })}
                          </TableCell>
                        ) : (
                          <TableCell className="tableCellData" align="center">
                            {dats.user && dats.user.firstName}
                          </TableCell>
                        )}
                        {respchange ? (
                          <TableCell className="tableCellData" align="center">
                            {dats.user &&
                              dats.user.map((item, i) => {
                                return <>{item.lastName}</>;
                              })}
                          </TableCell>
                        ) : (
                          <TableCell className="tableCellData" align="center">
                            {dats.user && dats.user.lastName}
                          </TableCell>
                        )}
                        {/* commented */}
                        {/* {user?.admin?.role != "admin" ? (
                          <TableCell className="tableCellData" align="center">
                            <ViewListIcon
                            style={{cursor:"pointer"}}
                              onClick={() => {
                                ViewedPopUps(dats);
                              }}
                            />
                          </TableCell>
                        ) : (
                          ""
                        )} */}
                        <TableCell className="tableCellData" align="center">
                          {dats?.isReviewed ? "Yes" : "No"}
                        </TableCell>
                        <TableCell className="tableCellData" align="center">
                          <button
                            className="bttn btn-success"
                            type="button"
                            onClick={() => {
                              viewdata(dats);
                            }}
                          >
                            <VisibilityIcon />
                          </button>
                        </TableCell>
                        <TableCell className="tableCellData" align="center">
                          {dats?.type}
                        </TableCell>
                        {/* <TableCell className="tableCellData" align="center">
                        <button
                          onClick={() => {
                           weatherGo(dats);
                          }}
                          className="bttn btn btnupdate"
                          type="button"
                        >
                          Weather
                        </button>
                      </TableCell> */}
                        <TableCell className="tableCellData" align="center">
                          <button
                            className={
                              "bttn btn-" +
                              (dats.isDelete ? "success" : "danger")
                            }
                            type="button"
                            onClick={() => {
                              deletereport(dats._id);
                            }}
                          >
                            <DeleteForeverSharpIcon />
                          </button>
                        </TableCell>
                        <TableCell className="tableCellData" align="center">
                          <button
                            onClick={() => {
                              Userupdated(dats);
                            }}
                            className="bttn btn btnupdate"
                            type="button"
                          >
                            <EditSharpIcon />
                          </button>
                        </TableCell>

                        {/* <TableCell className="tableCellData" align="left">
                          <div className="statuss">
                            <label>{dats.isBlocked ? "Blocked" : "Live"}</label>
                            <Switch
                              onClick={() => {
                                dats.isBlocked
                                  ? addagainblock(dats._id)
                                  : blockUser(dats._id);
                              }}
                              checked={!dats.isBlocked}
                              color={dats.isBlocked ? "warning" : "success"}
                            />
                          </div>
                        </TableCell> */}
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {/* </InfinitScroll> */}

            {loader ? <ReportView resp={viewResp} close={setLoader} /> : ""}
            {loader4 ? (
              <ReportUpdate
                id={addId}
                updstate={updatestate}
                update={handleupdate}
                close={setLoader4}
              />
            ) : (
              ""
            )}
            {loader1 ? (
              <DeleteReport
                sortedProducts={sortedProducts}
                handleDelete={handleDelete}
                id={delId}
                updstate={updatestate}
                close={setLoader1}
              />
            ) : (
              ""
            )}
            {loader2 ? (
              <ReportBlock
                id={delId}
                updstate={updatestate}
                blocked={handleblocked}
                close={setLoader2}
              />
            ) : (
              ""
            )}
            {loader3 ? (
              <AddReportagain
                id={addId}
                updstate={updatestate}
                unblocked={handleblocked}
                close={setLoader3}
              />
            ) : (
              ""
            )}
            {loader5 ? (
              <ViewedPopUp
                resp={addId}
                title="report"
                // updstate={updatestate}
                // update={handleupdate}
                close={setLoader5}
                // propicdel={picdel}
              />
            ) : (
              ""
            )}
            <AddReport
              open={openAddReport}
              handleClickOpen={handleAddReport}
              handleClose={handleCloseReport}
              handleCreate={handleSubmit}
              loader={createLoader}
            />
          </TableContainer>

          <div className="paginationContainer">
            <Pagination
              count={Math.ceil(UserCount / 10)}
              page={currentPage}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              sx={{
                "& .MuiPaginationItem-root": {
                  height: "50px",
                  width: "50px",
                  fontSize: "1rem",
                },
                "& .Mui-selected": {
                  backgroundColor: "#488fbf !important",
                  color: "white",
                  border: "none",
                },
              }}
            />
          </div>
        </>
      )}
      {netcheckk ? (
        <div className="loaderr">
          <img src={Caution}></img>
          <h3>Your connection is not available</h3>
        </div>
      ) : (
        ""
      )}
      <FormDialog
        open={fileterbool}
        handleClose={closeModal}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        callback={FilterDate}
        handleFilter={handleFilter}
      />
    </div>
  );
}
