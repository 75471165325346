import { HTTP_CLIENT } from "../utils/interceptor";
import { GROUP_ENDPOINTTS } from "../utils/endpoint";
const getGroups = ({ page, search }) => {
  let query = `?pageNo=${page}&perPage=10`;
  if (search) {
    query += `&search=${search}`;
  }
  return HTTP_CLIENT.get(GROUP_ENDPOINTTS.getGroups + query);
};

const deleteGroup = (id) => {
  return HTTP_CLIENT.put(GROUP_ENDPOINTTS.delete + id);
};

const getGroupChat = (id, pageNo) => {
  return HTTP_CLIENT.get(
    GROUP_ENDPOINTTS.groupChat + `?groupId=${id}&pageNo=${pageNo}&perPage=10`
  );
};

const getGroupMsgs = (id, pageNo) => {
  return HTTP_CLIENT.get(GROUP_ENDPOINTTS.groupMsgs + id + `?pageNo=${pageNo}`);
};

const getAllUsers = () => {
  return HTTP_CLIENT.get(GROUP_ENDPOINTTS.getAllUsers);
};

const addGroup = (obj) => {
  let formbody = new FormData();
  Object.keys(obj).forEach((key, inx) => {
    formbody.append(key, obj[key]);
  });

  return HTTP_CLIENT.post(GROUP_ENDPOINTTS.addGroup, formbody);
};

const addGroupMessage = (params) => {
  return HTTP_CLIENT.post(GROUP_ENDPOINTTS.addMessage, params);
};

const editGroup = (id, obj) => {
  let formbody = new FormData();
  Object.keys(obj).forEach((key, inx) => {
    formbody.append(key, obj[key]);
  });

  return HTTP_CLIENT.put(GROUP_ENDPOINTTS.editGroup + id, formbody);
};

const getAllGroupRequests = (id) => {
  return HTTP_CLIENT.get(GROUP_ENDPOINTTS.groupRequests + "/" + id);
};

const manageRequest = (id, accept) => {
  return HTTP_CLIENT.put(
    accept
      ? GROUP_ENDPOINTTS.acceptRequest + "/" + id
      : GROUP_ENDPOINTTS.rejectRequest + "/" + id
  );
};

export {
  getGroups,
  deleteGroup,
  getAllUsers,
  addGroup,
  editGroup,
  getGroupChat,
  getGroupMsgs,
  getAllGroupRequests,
  manageRequest,
  addGroupMessage,
};
